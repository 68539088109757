import { Snackbar } from '@material-ui/core';
import { Alert as MuiAlert } from '@material-ui/lab';
import styled from 'styled-components';
import { Translate } from 'i18n/Translate';
import { SUCCESS_NOTIFICATION } from './NotificationType';

const AUTO_HIDE_DURATION = 5000;
const CLICK_AWAY_REASON = 'clickaway';

const StyledSnackbar = styled(Snackbar)`
  bottom: 72px;
`;

const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

export const Notification = ({
  text,
  isOpen,
  setIsOpen,
  type = SUCCESS_NOTIFICATION,
}) => {
  const handleClose = (event, reason) => {
    if (CLICK_AWAY_REASON === reason) {
      return;
    }

    setIsOpen(false);
  };

  return (
    <StyledSnackbar
      open={isOpen}
      autoHideDuration={AUTO_HIDE_DURATION}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={type}>
        <Translate text={text} />
      </Alert>
    </StyledSnackbar>
  );
};

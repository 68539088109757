import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useApi } from 'hooks/useApi';
import { NotificationContext } from 'Components/Notifications/NotificationContext';
import { ERROR_NOTIFICATION } from 'Components/Notifications/NotificationType';
import { QueryKeys } from 'QueryKeys';

const API_RESOURCE = 'configuration';

const useFetchConfig = () => {
  const { get } = useApi();
  const { showNotification } = useContext(NotificationContext);

  return useQuery(QueryKeys.FETCH_CONFIGURATION, () => get(API_RESOURCE), {
    onError: () =>
      showNotification(ERROR_NOTIFICATION, 'register.status.error'),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });
};

export default useFetchConfig;

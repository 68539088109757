import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Grid, TextField } from '@material-ui/core';
import { Translate } from 'i18n/Translate';

export const EmailFragment = ({ control, isDisabled }) => {
  const { formatMessage } = useIntl();

  return (
    <Grid item xs={12} sm={6}>
      <Controller
        control={control}
        name="email"
        rules={{
          required: formatMessage({
            id: 'register.input.email.required',
          }),
          pattern: {
            value: /\S+@\S+\.\S+/,
            message: formatMessage({
              id: 'register.input.email.invalid',
            }),
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            disabled={isDisabled}
            error={!!error}
            fullWidth
            helperText={error?.message}
            InputLabelProps={{ shrink: true }}
            label={<Translate text="register.input.email" />}
            margin="dense"
            placeholder={formatMessage({
              id: 'register.input.email.placeholder',
            })}
            variant="outlined"
            {...field}
          />
        )}
      />
    </Grid>
  );
};

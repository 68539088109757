import { createContext } from 'react';
import useFetchConfig from 'hooks/useFetchConfig';

export const ConfigurationContext = createContext({});
ConfigurationContext.displayName = 'Configuration';

export const ConfigurationProvider = ({ children }) => {
  const { isLoading, data: configuration } = useFetchConfig();

  return (
    <ConfigurationContext.Provider
      value={{
        configuration,
        isLoading,
      }}
    >
      {children}
    </ConfigurationContext.Provider>
  );
};

import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Header } from 'Components/Header';
import { Footer } from 'Components/Footer';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: `calc(100vh - ${theme.spacing(20)}px)`,
  },
}));

export const Layout = ({ children }) => {
  const theme = useTheme();
  const classes = useStyles();
  const isTabletUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>
      <Header />
      <Box w={1} p={2}>
        <Box
          display="flex"
          flexDirection="column"
          ml="auto"
          mr="auto"
          mt={3}
          width={isTabletUp ? theme.spacing(81) : 1}
        >
          <Box className={classes.root}>{children}</Box>
          <Footer />
        </Box>
      </Box>
    </>
  );
};

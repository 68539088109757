import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { EMPTY_ARRAY } from 'AppConstants';

export const useCountries = () => {
  const { locale } = useIntl();
  const [countries, setCountries] = useState(EMPTY_ARRAY);

  useEffect(() => {
    import(`i18n/resources/countries_${locale}.json`).then(
      ({ default: options }) => setCountries(options),
    );
  }, [locale]);

  return countries;
};

import { isEqual, startsWith, replace, size } from 'lodash-es';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Grid, TextField } from '@material-ui/core';
import InputMask from 'react-input-mask';
import { Translate } from 'i18n/Translate';
import { EMPTY_SPACE, EMPTY_STRING, UNDEFINED } from 'AppConstants';
import { isValidModulo10Recursive } from 'utils/mod10recursive';

/**
 * The length of the insurance number.
 */
const TOTAL_NUMBER_LENGTH = 20;

/**
 * The actual length of the insurance number w/o the checksum.
 */
const ACTUAL_INSURANCE_NUMBER_LENGTH = TOTAL_NUMBER_LENGTH - 1;

/**
 * The expected string the insurance number has to be prepended with
 */
const REQUIRED_PREPENDED_TEXT = '80756';

const getValidateFunctionWithMessage =
  ({ invalidLengthMessage, invalidValueMessage }) =>
  (rawValue) => {
    const value = replace(rawValue, / /g, EMPTY_STRING);
    if (!value) {
      return UNDEFINED;
    }

    if (!isEqual(size(value), TOTAL_NUMBER_LENGTH)) {
      return invalidLengthMessage;
    }

    if (!startsWith(value, REQUIRED_PREPENDED_TEXT)) {
      return invalidValueMessage;
    }

    const actualInsuranceCardNumber = value.substring(
      0,
      ACTUAL_INSURANCE_NUMBER_LENGTH,
    );
    const expectedChecksum = value.substring(ACTUAL_INSURANCE_NUMBER_LENGTH);

    const hasValidCheckSum = isValidModulo10Recursive(
      actualInsuranceCardNumber,
      expectedChecksum,
    );

    return hasValidCheckSum || invalidValueMessage;
  };

export const SwissInsuranceCardNumberFragment = ({ control, isDisabled }) => {
  const { formatMessage } = useIntl();

  return (
    <Grid item xs={12} sm={6}>
      <Controller
        control={control}
        name="swissInsuranceCardNumber"
        rules={{
          required: formatMessage({
            id: 'register.input.swissInsuranceCardNumber.required',
          }),
          validate: getValidateFunctionWithMessage({
            invalidLengthMessage: formatMessage({
              id: 'register.input.swissInsuranceCardNumber.length',
            }),
            invalidValueMessage: formatMessage({
              id: 'register.input.swissInsuranceCardNumber.incorrect',
            }),
          }),
        }}
        render={({ field, fieldState: { error } }) => (
          <InputMask
            {...field}
            mask="99999 99999 99999 99999"
            maskChar={EMPTY_SPACE}
            disabled={isDisabled}
            alwaysShowMask={false}
          >
            {(inputProps) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                InputLabelProps={{ shrink: true }}
                label={
                  <Translate text="register.input.swissInsuranceCardNumber" />
                }
                margin="dense"
                placeholder={formatMessage({
                  id: 'register.input.swissInsuranceCardNumber.placeholder',
                })}
                variant="outlined"
                {...inputProps}
              />
            )}
          </InputMask>
        )}
      />
    </Grid>
  );
};

export const isValidModulo10Recursive = (
  insuranceCardNumber,
  givenCheckSum,
) => {
  const alphabet = [0, 9, 4, 6, 8, 2, 7, 1, 3, 5];
  let carry = 0;

  [...insuranceCardNumber].forEach((n) => {
    carry = alphabet[(carry + (n - '0')) % 10];
  });

  const calculatedCheckSum = (10 - carry) % 10;

  return Number.parseInt(givenCheckSum, 10) === calculatedCheckSum;
};

import { forwardRef } from 'react';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import { isNaN } from 'lodash-es';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Translate } from 'i18n/Translate';
import { DATE_FORMAT } from 'AppConstants';

// @note: Add forwardRef to fix issue with RHF v6.12.1
// @issue: https://github.com/react-hook-form/react-hook-form/issues/3411
// eslint-disable-next-line react/display-name
const RefKeyboardDatePicker = forwardRef((props, ref) => (
  <KeyboardDatePicker inputRef={ref} {...props} />
));

const minValidateDate = '1900-01-01';
const maxValidateDate = `${new Date().getFullYear() - 1}-12-31`;

export const DateOfBirthFragment = ({ control, isDisabled }) => {
  const { formatMessage } = useIntl();

  function checkDate(dateOfBirth) {
    return (
      !isNaN(dateOfBirth) &&
      dateOfBirth > new Date(minValidateDate).getTime() &&
      dateOfBirth < new Date(maxValidateDate).getTime()
    );
  }

  return (
    <Grid item xs={12} sm={6}>
      <Controller
        control={control}
        name="dateOfBirth"
        initialFocusedDate={null}
        defaultValue={null}
        rules={{
          required: formatMessage({
            id: 'register.input.dateOfBirth.required',
          }),
          validate: (value) =>
            checkDate(value?.getTime?.()) ||
            formatMessage({
              id: 'register.input.dateOfBirth.incorrect',
            }),
        }}
        render={({ field, fieldState: { error } }) => (
          <RefKeyboardDatePicker
            autoOk
            readOnly={isDisabled}
            disableFuture
            error={!!error}
            format={DATE_FORMAT}
            fullWidth
            helperText={error?.message}
            InputLabelProps={{ shrink: true }}
            inputVariant="outlined"
            label={<Translate text="register.input.dateOfBirth" />}
            margin="dense"
            placeholder={formatMessage({
              id: 'register.input.dateOfBirth.placeholder',
            })}
            {...field}
          />
        )}
      />
    </Grid>
  );
};

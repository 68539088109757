import { Box, Link } from '@material-ui/core';
import { SKIP_RENDER } from 'AppConstants';
import { Translate } from 'i18n/Translate';
import useHeaderFooterConfig from 'hooks/useEmbeddedMode';
import useConfig from 'hooks/useConfig';

export const Footer = () => {
  const { isEmbedded: shouldBeHidden } = useHeaderFooterConfig();
  const { configuration } = useConfig();

  if (shouldBeHidden) {
    return SKIP_RENDER;
  }

  return (
    <Box display="flex" justifyContent="flex-start" alignItems="center" mt={4}>
      <Box p={1}>
        <Link
          color="textPrimary"
          href="https://www.keyper.io/imprint/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Translate text="footer.imprint" />
        </Link>
      </Box>

      <Box>|</Box>

      <Box p={1}>
        <Link
          color="textPrimary"
          href={configuration?.privacyPolicyUrl}
          rel="noopener noreferrer"
          target="_blank"
        >
          <Translate text="footer.privacy" />
        </Link>
      </Box>

      <Box>|</Box>

      <Box p={1}>
        <Link
          color="textPrimary"
          href={configuration?.gtcUrl}
          rel="noopener noreferrer"
          target="_blank"
        >
          <Translate text="footer.terms" />
        </Link>
      </Box>
    </Box>
  );
};

import { useMemo } from 'react';
import { ThemeProvider as ScThemeProvider } from 'styled-components';
import {
  createTheme,
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { AppColors } from 'AppColors';
import { GlobalStyle } from 'GlobalStyle';
import useConfig from 'hooks/useConfig';
import useEmbeddedMode from 'hooks/useEmbeddedMode';

const DEFAULT_THEME_COLORS = {
  text: AppColors.text,
  red: AppColors.red,
};

export const ThemeProvider = ({ children }) => {
  const { configuration } = useConfig();
  const { isEmbedded: hideBackground } = useEmbeddedMode();
  const main = useMemo(
    () => configuration?.mainColor || AppColors.mainLightest,
    [configuration],
  );

  return (
    <StylesProvider injectFirst>
      <ScThemeProvider
        theme={{
          ...DEFAULT_THEME_COLORS,
          main,
        }}
      >
        <GlobalStyle hideBackground={hideBackground} />
        <MuiThemeProvider
          theme={createTheme({
            overrides: {
              MuiCssBaseline: {
                '@global': {
                  body: {
                    backgroundColor: hideBackground
                      ? 'transparent'
                      : AppColors.background,
                  },
                },
              },
              MuiSelect: {
                root: {
                  color: AppColors.text,
                },
              },
            },
            typography: {
              button: {
                textTransform: 'none',
              },
              allVariants: {
                color: AppColors.text,
              },
              h5: {
                color: AppColors.black,
              },
              h6: {
                color: AppColors.black,
                fontSize: '1rem',
              },
              subtitle1: {
                color: AppColors.black,
              },
            },
            palette: {
              primary: {
                main,
              },
              secondary: {
                main,
              },
            },
          })}
        >
          <CssBaseline />
          {children}
        </MuiThemeProvider>
      </ScThemeProvider>
    </StylesProvider>
  );
};

import { QueryClient, QueryClientProvider } from 'react-query';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { NotificationProvider } from 'Components/Notifications/NotificationProvider';
import { ThemeProvider } from 'ThemeProvider';
import { IntlProvider } from 'i18n/IntlProvider';
import { ConfigurationProvider } from 'ConfigurationProvider';

const DEFAULT_QUERY_CLIENT_OPTIONS = {
  defaultOptions: {
    staleTime: Number.MAX_SAFE_INTEGER,
    queries: {
      retry: 0,
    },
  },
};

export const AppProviders = ({ children }) => (
  <QueryClientProvider client={new QueryClient(DEFAULT_QUERY_CLIENT_OPTIONS)}>
    <ConfigurationProvider>
      <ThemeProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <IntlProvider>
            <NotificationProvider>{children}</NotificationProvider>
          </IntlProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </ConfigurationProvider>
  </QueryClientProvider>
);

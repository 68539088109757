import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import App from './App';

const MOUNT_ROOT = 'root';

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById(MOUNT_ROOT),
);

import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { AppProviders } from 'AppProviders';
import { ROOT_URL } from 'AppConstants';
import { RegistrationRoute } from 'Routes/Registration';

const App = () => (
  <BrowserRouter>
    <Switch>
      <AppProviders>
        <Route path={ROOT_URL} component={RegistrationRoute} />
      </AppProviders>
    </Switch>
  </BrowserRouter>
);

export default App;

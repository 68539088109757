import { useContext } from 'react';
import { useMutation } from 'react-query';
import { useApi } from 'hooks/useApi';
import { NotificationContext } from 'Components/Notifications/NotificationContext';
import {
  ERROR_NOTIFICATION,
  SUCCESS_NOTIFICATION,
} from 'Components/Notifications/NotificationType';

const useSubmitForm = ({ onSuccess }) => {
  const { post } = useApi();
  const { showNotification } = useContext(NotificationContext);

  return useMutation((payload) => post(payload), {
    onSuccess: (data) => {
      onSuccess(data);

      return showNotification(SUCCESS_NOTIFICATION, 'register.status.success');
    },
    onError: () =>
      showNotification(ERROR_NOTIFICATION, 'register.status.error'),
  });
};

export default useSubmitForm;

import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import * as queryString from 'query-string';
import { has } from 'lodash-es';

const EMBEDDED_MODE_QUERY_PARAM = 'embedded';

const useEmbeddedMode = () => {
  const location = useLocation();
  const isEmbedded = useMemo(
    () => has(queryString.parse(location?.search), EMBEDDED_MODE_QUERY_PARAM),
    [location],
  );

  return {
    isEmbedded,
  };
};

export default useEmbeddedMode;

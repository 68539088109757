import { Backdrop, Box, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { AppColors } from 'AppColors';
import useConfig from 'hooks/useConfig';

const DEFAULT_DRAWER_VALUE = 999;

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme?.zIndex?.drawer || DEFAULT_DRAWER_VALUE + 1,
  },
  progress: {
    color: AppColors.black,
  },
}));

export const BackdropSpinner = ({ isOpen }) => {
  const classes = useStyles();
  const { isLoading } = useConfig();

  return (
    <Box>
      <Backdrop
        className={classes.backdrop}
        data-testid="@keyper-backdrop"
        open={isOpen}
      >
        <CircularProgress
          className={clsx({
            [classes.progress]: isLoading,
          })}
        />
      </Backdrop>
    </Box>
  );
};
